import React, {useState, Fragment} from 'react'
import LittleNavbar from '../components/LittleNavbar'
import LittleSidebar from '../components/LittleSidebar';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';

const SubscribePage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const hideSubscribe = true;
    return (
        <Fragment>
            <LittleSidebar isOpen={isOpen} toggle={toggle} hideSubscribe={hideSubscribe} />
            <LittleNavbar hideSubscribe={hideSubscribe} toggle={toggle} />
            <Subscribe />
            <Footer />
        </Fragment>
    )
}

export default SubscribePage;
