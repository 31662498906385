import React from 'react';
import { Container, ContactWrapper, ContactRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormTextArea, FormSelect, Foption, FormCheckBoxLabel, FormCheckBoxInput, FormButton, ImgWrap, Img } from './SuggestionsContractElements';
import img from '../../images/suggestionContract.svg';
import fire from '../utilities/firebase';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SuggestionsContract = ({imgStart}) => {
    
     var state = {
        customer: {
          name : '',
          email : '',
          projectCategory : 'Education',
          description : '',
          projectType: 'Quotation',
          wantSubscription: 'false',
          date: new Date().toString()
        }
      }

    const handleNameChanged = event => {
        var customer        = state.customer;
        customer.name  = event.target.value;
        state.customer = customer;
      }
    
    const  handleEmailChanged = event => {
        var customer      = state.customer;
        customer.email = event.target.value;
        state.customer = customer;
      }

    const handleProjectCategoryChanged = event => {
        var customer = state.customer;
        customer.projectCategory = event.target.value;
        state.customer = customer;
    }
    
    const  handleDescriptionChanged = event => {
        var customer    = state.customer;
        customer.description = event.target.value;
        state.customer = customer;
      }

      const  handleProjectTypeChanged = event => {
        var customer    = state.customer;
        customer.projectType = event.target.value;
        state.customer = customer;
      }

      const  handleSuggestionChanged = event => {
        var customer    = state.customer;
        let checkedStatus = (event.target.value == 'on') ? true : false;
        customer.wantSubscription = checkedStatus;
        state.customer = customer;
      }

    const handleSubmit = async (event) => {
        event.preventDefault();
        toast.configure();
        var formData = state.customer;
        var databaseName = 'quote';
        // var typeMessage = 'suggestion';
        // if(state.customer.projectType === 'Hire') {
        //     databaseName = 'contract';
        //     typeMessage = 'request';
        // }
        fire.database().ref(databaseName).orderByKey().limitToLast(100);
        await fire.database().ref(databaseName).push(formData);
        document.getElementById("suggestion-form").reset();
        toast.success('We have successfully received your request!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        var customer = state.customer;
        customer.name = '';
        customer.email = '';
        customer.projectCategory = 'Education';
        customer.description = '';
        customer.projectType = 'Quotation';
        customer.wantSubscription = 'false';
        customer.date = new Date().toString();
        state.customer = customer;
    };

    return (
            <Container id='suggestion'>
                <ContactWrapper>
                    <ContactRow imgStart={imgStart}>
                        <Column1>
                            <FormWrap>
                                <FormContent>
                                    <Form onSubmit={handleSubmit} id="suggestion-form">
                                        <FormH1>Get a Quote</FormH1>
                                    <FormP>Drop us a line, and our representative will contact you within 12 hours
                                        to arrange an initial discussion.</FormP>
                                        <FormLabel htmlFor='name'>Name</FormLabel>
                                        <FormInput name="name" type='text' onChange={handleNameChanged.bind()} required />
                                        <FormLabel htmlFor='email'>Email</FormLabel>
                                        <FormInput name="email" type='email' onChange={handleEmailChanged.bind()} required />
                                        <FormLabel htmlFor='projectcategory'>Project Category</FormLabel>
                                        <FormSelect name="projectcategory" onChange={handleProjectCategoryChanged.bind()}>
                                            <Foption name="education">Education</Foption>
                                            <Foption name="entertainment">Entertainment</Foption>
                                            <Foption name="hospitality">Hospitality</Foption>
                                            <Foption name="medical">Medical</Foption>
                                            <Foption name="technology">Technology</Foption>
                                            <Foption name="sports">Sports</Foption>
                                            <Foption name="other">Other</Foption>
                                        </FormSelect>
                                        <FormLabel htmlFor='description'>Short Description</FormLabel>
                                        <FormTextArea name="description" type='text' onChange={handleDescriptionChanged.bind()} required />
                                        {/* <FormLabel htmlFor='projecttype'>Project Type</FormLabel>
                                        <FormSelect name="projecttype" onChange={handleProjectTypeChanged.bind()}>
                                            <Foption name="suggestion">Suggestion</Foption>
                                            <Foption name="contract">Hire</Foption>
                                        </FormSelect> */}
                                        
                                        <FormCheckBoxLabel htmlFor="subscribe"><FormCheckBoxInput type="checkbox" name="subscribe" onChange={handleSuggestionChanged.bind()} />Subscribe to our new releases</FormCheckBoxLabel>
                                        <FormButton type='submit'>Send Details</FormButton>
                                    </Form>
                                </FormContent>
                            </FormWrap>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt="contact" />
                            </ImgWrap>
                        </Column2>
                    </ContactRow>
                </ContactWrapper>
            </Container>
    )
}

export default SuggestionsContract
