import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkScroll } from 'react-scroll';
import { Link as LinkRouter } from 'react-router-dom';
export const SidebarContainer = styled.div`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition : 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    background-color: #010606;
    height: 100%;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const CloseIcon = styled(FaTimes)`
    color: var(--app-primary-color);
`;

export const SidebarWrapper = styled.div`
    color: var(--app-primary-color);

`;

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    frid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(8, 50px);
    }
`;

export const SidebarLink = styled(LinkScroll)`
    padding: 2%;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: var(--app-secondary-color);
    cursor: pointer;

    &:hover {
        color: var(--app-primary-color)1;
        transition: 0.2s ease-in-out;
    }
`;

export const SidebarRouteLink = styled(LinkRouter)`
    padding: 2%;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: var(--app-secondary-color);
    cursor: pointer;

    &:hover {
        color: var(--app-primary-color)1;
        transition: 0.2s ease-in-out;
    }
`;

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 2%;
`;

export const SidebarRoute = styled(LinkScroll)`
    border-radius: 50px;
    background: var(--app-primary-color);
    white-space: nowrap;
    padding: 16px 64px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
    }
`;