import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute} from './LittleSidebarElements';

const LittleSidebar = ({ isOpen, toggle, hideSubscribe}) => {

    const goToHome = () => {
        var url = window.location.protocol + "//" + window.location.host;
        window.location = url;
    }

    const goToSubscribe = () => {
        var url = window.location.protocol + "//" + window.location.host+'/subscribe';
        window.location = url;
    }

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                {/* <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>About</SidebarLink>
                    <SidebarLink to="products" onClick={toggle}>Products</SidebarLink>
                    <SidebarLink to="suggestion" onClick={toggle}>Hire</SidebarLink>
                    <SidebarLink to="upcoming" onClick={toggle}>Upcoming</SidebarLink>
                    <SidebarLink to="support" onClick={toggle}>Support Us</SidebarLink>  
                    <SidebarLink to="contact" onClick={toggle}>Contact Us</SidebarLink>
                </SidebarMenu> */}
                <SideBtnWrap>
                    <SidebarRoute onClick={goToHome}>Home</SidebarRoute>
                    { 
                        !hideSubscribe && <SidebarRoute onClick={goToSubscribe}>Subscribe</SidebarRoute>
                    }
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default LittleSidebar
 