import React from 'react'
import { ProductContainer, ProductH1, ProductWrapper, ProductCard, ProductIcon, ProductH2, ProductP} from './ProductElements';
import Icon1 from '../../images/covid-4.svg';
import Icon2 from '../../images/dShort.png';
import Icon3 from '../../images/portfolio.png';
import LSLogo from '../../images/leadingsounds-logo.jpg';
import SchoolLogo from '../../images/school.png';
import SchoolLogo1 from '../../images/school-1.png';
import HHLogo from '../../images/hobbyhopp.svg';
import GTLogo from '../../images/gevan.png';
import ASLogo from '../../images/amaara.svg';
import { FaShare } from 'react-icons/fa';

const ProductSection = () => {

    return (
        <ProductContainer id="products">
            <ProductH1>Projects</ProductH1>
            <ProductWrapper>
                <a href="https://hobbyhopp.in/" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={HHLogo} />
                    <ProductH2>HobbyHopp</ProductH2>
                    <ProductP>Event & Community management web app</ProductP>
                    <a href="https://hobbyhopp.in/" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">HobbyHopp <FaShare /></a>
                </ProductCard>
                </a>
                <a href="https://dd-amaara-seetu.web.app/" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={ASLogo} />
                    <ProductH2>Amaara Seetu</ProductH2>
                    <ProductP>Seetu scheme app for UK based client(yet to launch)</ProductP>
                    <a href="https://dd-amaara-seetu.web.app/" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">Amaara Seetu <FaShare /></a>
                </ProductCard>
                </a>
                <a href="https://gevan-tech.web.app/" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={GTLogo} />
                    <ProductH2>Gevan Tech</ProductH2>
                    <ProductP>Portfolio web app for Gevan Tech(yet to launch)</ProductP>
                    <a href="https://gevan-tech.web.app/" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">Gevan Tech <FaShare /></a>
                </ProductCard>
                </a>
                <a href="https://stjohns-rkee.web.app" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={SchoolLogo1} />
                    <ProductH2>St Johns</ProductH2>
                    <ProductP>Web app developed for the India(Roorkee) based school.</ProductP>
                    <a href="https://stjohns-rkee.web.app" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">St. Johns <FaShare /></a>
                </ProductCard>
                </a>
                {/* <a href="https://laureaterke.com" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={SchoolLogo} />
                    <ProductH2>Laureate</ProductH2>
                    <ProductP>Web app developed for the India(Roorkee) based school.</ProductP>
                    <a href="https://laureaterke.com" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">laureaterke.com <FaShare /></a>
                </ProductCard>
                </a> */}
                <a href="https://covitrail.web.app" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={Icon1} />
                    <ProductH2>Track Covid-19 Outbreak</ProductH2>
                    <ProductP>Track Corona virus outbreak around the world</ProductP>
                    <a href="https://covitrail.web.app" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">Covitrail <FaShare /></a>
                </ProductCard>
                </a>
                <a href="https://dshort.in/" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={Icon2} />
                    <ProductH2>Short Urls</ProductH2>
                    <ProductP>This site facilitates to reduce the length of long urls</ProductP>
                    <a href="https://dshort.in/" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">dshort <FaShare /></a>
                </ProductCard>
                </a>
                {/* <a href="https://abhi-07.web.app" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={Icon3} />
                    <ProductH2>Personal Portfolio</ProductH2>
                    <ProductP>Contact us to get your portfolio developed</ProductP>
                    <a href="https://abhi-07.web.app" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">Portfolio <FaShare /></a>
                </ProductCard>
                </a> */}
                {/* <a href="https://resume-c1772.web.app/" target="_blank" rel="noreferrer">
                <ProductCard>
                    <ProductIcon src={Icon3} />
                    <ProductH2>Personal Portfolio</ProductH2>
                    <ProductP>Contact us to get your portfolio developed</ProductP>
                    <a href="https://resume-c1772.web.app/" target="_blank" className="dockdevelopers-product-link" rel="noreferrer">resume-c1772.web.app <FaShare /></a>
                </ProductCard>
                </a> */}
            </ProductWrapper>
        </ProductContainer>
    )
}

export default ProductSection;
