import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormButton, ImgWrap, Img } from './LoginForm';
import img1 from '../../images/login.svg';
import fire from '../utilities/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'

const LoginForm = ({ onLogin }) => {
    const navigate = useNavigate();


    var state = {
        customer: {
            id: '',
            password: ''
        },
    }

    const handleIdChanged = event => {
        var customer = state.customer;
        customer.id = event.target.value;
        state.customer = customer;
    }

    const handlePasswordChanged = event => {
        var customer = state.customer;
        customer.password = event.target.value;
        state.customer = customer;
    }


    const handleLogin = event => {
        toast.warning('Hold On, logging you in...', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        var formData = state.customer;
        const proposalDocRef = fire.firestore().collection('proposal').doc(formData.id);
        proposalDocRef.get().then((doc) => {
            toast.dismiss();
            if (doc.data() && !doc.data().isBlocked) {
                if (doc.data().password === formData.password) onLogin(formData.id, doc.data());
                else {
                    toast.error('Please check your password', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            }
            else {
                toast.error('Please check your username', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }
        }, (error) => {
            toast.dismiss();
            toast.error('We are facing some technical problems. Please try again after sometime', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            }).then(() => {
                navigate('/');
            });
        }
        );
    };

    return (
        <Container id='love-form'>
            <ToastContainer />
            <SubscribeWrapper>

                <SubscribeRow imgStart={true}>
                    <Column1>
                        <FormWrap>
                            <FormContent>
                                <Form onSubmit={handleLogin} id="date-planner-form">
                                    <FormH1>Login</FormH1>
                                    <FormP>Unlock the door to endless possibilities. Sign in to explore your personalized experience.<br></br>
                                        Remember security starts with you. Keep your password safe and don't share it with anyone.
                                    </FormP>

                                    <FormLabel htmlFor='id'>Username</FormLabel>
                                    <FormInput name="id" type='text' onChange={handleIdChanged.bind()} required />

                                    <FormLabel htmlFor='locationtype'>Password</FormLabel>
                                    <FormInput name="password" type='text' onChange={handlePasswordChanged.bind()} required />

                                    <FormButton type='submit'>Login</FormButton>
                                </Form>
                            </FormContent>
                        </FormWrap>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img1} alt="login" />
                        </ImgWrap>
                    </Column2>
                </SubscribeRow>

            </SubscribeWrapper>
        </Container>
    )
}

export default LoginForm