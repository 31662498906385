import React, {useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavRouteLinks, NavBtn, NavBtnLink} from './LittleNavbarElements';
import DDLogo from '../../images/DockDevelopers_Logo.png';

const LittleNavbar = ({ hideSubscribe, toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 20) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    // const toggleHome = () => {
    //     scroll.scrollToTop();
    // }

    const goToHome = () => {
        var url = window.location.protocol + "//" + window.location.host;
        window.location = url;
    }

    const goToSubscribe = () => {
        var url = window.location.protocol + "//" + window.location.host+'/subscribe';
        window.location = url;
    }

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo onClick={goToHome}>
                        <img src={DDLogo} alt='DockDevelopers' width={180} height={18} /></NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        {/* <NavItem>
                            <NavLinks to="about"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="products"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Products</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="suggestion"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Get a Quote</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="upcoming"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Upcoming</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="support"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Support Us</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="contact"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Contact Us</NavLinks>
                        </NavItem> */}
                    </NavMenu>
                    <NavBtn>
                        <NavRouteLinks onClick={goToHome}
                            smooth={true} duration={500} spy={false} exact='true' offset={-80}
                        >Home</NavRouteLinks>
                        { !hideSubscribe ? <NavRouteLinks onClick={goToSubscribe}
                            smooth={true} duration={500} spy={false} exact='true' offset={-80}
                        >Subscribe</NavRouteLinks> : ''}
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
    );
}

export default LittleNavbar; 