import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialIcons, SocialIconLink, SocialLogo, WebsiteRights, } from './FooterElements';
import logo from '../../images/dd-logo-1.png';
import DDLogo from '../../images/DockDevelopers_Logo.png';

const Footer = () => {
    

    return (
        <FooterContainer>
            <FooterWrap>
                {/* <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to="/subscribe">Subscribe</FooterLink>
                            <FooterLink to="/subscribe">Testimoniaks</FooterLink>
                            <FooterLink to="/subscribe">Careers</FooterLink>
                            <FooterLink to="/subscribe">Investors</FooterLink>
                            <FooterLink to="/subscribe">Terms of Services</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <FooterLink to="/subscribe">Instagram</FooterLink>
                            <FooterLink to="/subscribe">Facebook</FooterLink>
                            <FooterLink to="/subscribe">Twitter</FooterLink>
                            <FooterLink to="/subscribe">LinkedIn</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer> */}
        <p className="aboutPara">We always strive to deliver best product with 100% client satisfaction. We give priority to client's requirements and develop the product around them.<br /><br />Our main goal is to develop the product in best possible and efficient way by determining which technologies to be used with main focus on reducing maintainance efforts.<br /><br />Till now, we have kept our promise of 100% client satisfaction but please let us know if we can make any improvement. </p>
                    <div><a href="/"><img src={DDLogo} alt='DockDevelopers' width={180} height={18} /></a></div>
                <SocialMedia>
                        <WebsiteRights>DockDevelopers &copy; {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                    <SocialMediaWrap>
                        {/* <SocialLogo to='/'>DockDevelopers</SocialLogo> */}
                        <SocialIcons>
                            <SocialIconLink href="https://www.facebook.com/dockdevelopers" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/dockdevelopers" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="https://twitter.com/dockdevelopers" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.linkedin.com/company/dockdevelopers" target="_blank" aria-label="LinkedIn">
                                <FaLinkedin />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
                <WebsiteRights>business@dockdevelopers.com</WebsiteRights>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer