
import firebase from 'firebase';
import 'firebase/functions'

var firebaseConfig = {
  apiKey: "AIzaSyBrVon4rPHlD0S_KQEkszJJuLkpTtg4Gkg",
  authDomain: "dockdevelopers-27abb.firebaseapp.com",
  projectId: "dockdevelopers-27abb",
  storageBucket: "dockdevelopers-27abb.appspot.com",
  messagingSenderId: "84992605238",
  appId: "1:84992605238:web:c0f553b7ee3f711294ec97"
};

var fire = firebase.initializeApp(firebaseConfig);

if (window.location.hostname === 'localhost') {
  console.log("testing locally -- hitting local functions and firestore emulators");
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

// Initialize Analytics and get a reference to the service
export const analytics = firebase.analytics();

export default fire;