import React from 'react';
import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormButton, ImgWrap, Img, SupportUsContainer, SupportUsWrapper, SuportUsLink, SupportUsImg } from './SubscribeElements';
import img from '../../images/subscribe.svg';
import fire from '../utilities/firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';

const Subscribe = ({ imgStart }) => {

    var state = {
        customer: {
            name: '',
            email: '',
            date: new Date().toString()
        }
    }

    const handleNameChanged = event => {
        var customer = state.customer;
        customer.name = event.target.value;
        state.customer = customer;
    }

    const handleEmailChanged = event => {
        var customer = state.customer;
        customer.email = event.target.value;
        state.customer = customer;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        toast.configure();
        var formData = state.customer;
        fire.database().ref('subscribe').orderByKey().limitToLast(100);
        await fire.database().ref('subscribe').push(formData);
        document.getElementById("subscribe-form").reset();
        toast.success('Thanks for subscribing to DockDevelopers newsletter!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
    };

    return (
        <Container id='subscribe'>
            <SubscribeWrapper>
                <SubscribeRow imgStart={imgStart}>
                    <Column1>
                        <FormWrap>
                            <FormContent>
                                <Form onSubmit={handleSubmit} id="subscribe-form">
                                    <FormH1>Subscribe</FormH1>
                                    <FormP>Subscribe to our new releases and we will make sure that you get an update whenever we release a new product.</FormP>
                                    <FormLabel htmlFor='name'>Name</FormLabel>
                                    <FormInput name="name" type='text' onChange={handleNameChanged.bind()} required />
                                    <FormLabel htmlFor='email'>Email</FormLabel>
                                    <FormInput name="email" type='email' onChange={handleEmailChanged.bind()} required />
                                    <FormButton type='submit'>Subscribe</FormButton>
                                </Form>
                            </FormContent>
                        </FormWrap>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt="subscribe" />
                        </ImgWrap>
                    </Column2>
                </SubscribeRow>
            </SubscribeWrapper>

            <SupportUsContainer>
                <SupportUsWrapper>
                    <SuportUsLink href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><SupportUsImg src={BMCIcon} /></SuportUsLink>
                </SupportUsWrapper>
            </SupportUsContainer>
        </Container>
    )
}

export default Subscribe