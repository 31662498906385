import React, { useState, Fragment, useEffect } from 'react'
import LoveNavbar from '../components/LoveNavbar'
import LoveForm from '../components/LoveForm';
import { useNavigate, useLocation } from 'react-router-dom'
import fire from '../components/utilities/firebase';
import { toast, ToastContainer } from 'react-toastify';

const LoveFormPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const location = useLocation();

    const id = location.pathname.slice(6);

    useEffect(() => {
        const proposalDocRef = fire.firestore().collection('proposal').doc(id);
        proposalDocRef.get().then((doc) => {
            if (doc.data() && !doc.data().isBlocked) {
                setData(doc.data());
            }
            else {
                navigate('/404');
            }
        }, (error) => {
            toast.error('We are facing some technical problems. Please try again after sometime', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            }).then(() => {
                navigate('/');
            });
        }
        );
    }, [navigate, id]);
    return (
        <Fragment>
            <ToastContainer />
            {/* <LittleSidebar isOpen={isOpen} toggle={toggle} /> */}
            {
                data && (
                    <>
                        <LoveNavbar title={data?.valentineName} />
                        <LoveForm id={id} data={data} />
                    </>
                )
            }
            {/* <Footer /> */}
        </Fragment>
    )
}

export default LoveFormPage;
