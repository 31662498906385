import React from 'react';
import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormTextArea, FormButton, ImgWrap, Img, SupportUsContainer, SupportUsWrapper, SuportUsLink, SupportUsImg } from './PasswordGenerator';
import img from '../../images/clientpassword.svg';
import fire from '../utilities/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';

const PasswordGenerator = ({ imgStart }) => {

    var state = {
        customer: {
            clientId: '',
            password: '',
            name: '',
            email: '',
            rating: '',
            comment: '',
            date: new Date().toString()
        }
    }

    const handleUserNameChanged = event => {
        var customer = state.customer;
        customer.clientId = event.target.value;
        state.customer = customer;
    }

    const handlePasswordChanged = event => {
        var customer = state.customer;
        customer.password = event.target.value;
        state.customer = customer;
    }

    const handleEmailChanged = event => {
        var customer = state.customer;
        customer.email = event.target.value;
        state.customer = customer;
    }

    const handleNameChanged = event => {
        var customer = state.customer;
        customer.name = event.target.value;
        state.customer = customer;
    }

    const handleOneRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '1';
        state.customer = customer;
        var ratingOne = document.getElementById('ratingOne');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingOne) {
            ratingOne.classList.add('is-active');
        }
    }

    const handleTwoRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '2t';
        state.customer = customer;
        var ratingTwo = document.getElementById('ratingTwo');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingTwo) {
            ratingTwo.classList.add('is-active');
        }
    }

    const handleThreeRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '3';
        state.customer = customer;
        var ratingThree = document.getElementById('ratingThree');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingThree) {
            ratingThree.classList.add('is-active');
        }
    }

    const handleFourRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '4';
        state.customer = customer;
        var ratingFour = document.getElementById('ratingFour');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFour) {
            ratingFour.classList.add('is-active');
        }
    }

    const handleFiveRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '5';
        state.customer = customer;
        var ratingFive = document.getElementById('ratingFive');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFive) {
            ratingFive.classList.add('is-active');
        }
    }

    const handleCommentChanged = event => {
        var customer = state.customer;
        customer.comment = event.target.value;
        state.customer = customer;
    }

    const handleSubmit = event => {
        toast.warning('Please wait, we are processing your request', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        var formData = state.customer;
        const clientdocpasswordRef = fire.firestore().collection('clientdocpassword').doc(formData.clientId);
        clientdocpasswordRef.get().then((doc) => {
            if (doc.data()) {
                toast.dismiss();
            }
            if (doc.data() && !doc.data().hasaccess) {
                toast.error('You don\'t have the permission to generate the password. Please drop a mail to us at business@dockdevelopers.com', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }
            else if (doc.data() && (doc.data().password == formData.password)) {
                fire.firestore().collection('clientpasswordgenerationdetails').add(formData).then((result) => {
                    if (result) {
                        document.getElementById("client-form").reset();
                        var elements = document.getElementsByClassName('is-active');
                        while (elements.length > 0) {
                            elements[0].classList.remove('is-active');
                        }
                        toast.success('You have successfully generated your supporting document password. Password - ' + doc.data().docpassword, {
                            position: "top-center",
                            autoClose: 1000000,
                            hideProgressBar: true,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    }
                },
                    (error) => {
                        toast.error('We are facing some technical problems. Please try again after sometime', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    });

            }
            else {
                toast.error('You have entered incorrect username or password. Please check your details or contact us!', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }
        },
            (error) => {
                toast.error('We are facing some technical problems. Please try again after sometime', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    };

    return (
        <Container id='clientpasswordgenerator'>
            <ToastContainer />
            <SubscribeWrapper>
                <SubscribeRow imgStart={imgStart}>
                    <Column1>
                        <FormWrap>
                            <FormContent>
                                <Form onSubmit={handleSubmit} id="client-form">
                                    <FormH1>Generate Password</FormH1>
                                    <FormP>Thanks for giving us an opportunity to serve you. We hope, you are satisfied with our work.<br /><br />Please fill up the below form to generate password for your delivery documents.<br /><br />Contact us if you need any help, we will be more than happy to help you out in any possible way.<br /> Please rate us and add your comments or suggestions to help us improve.</FormP>
                                    <FormLabel htmlFor='clientId'>Enter Client Id</FormLabel>
                                    <FormInput name="clientId" type='text' onChange={handleUserNameChanged.bind()} required />
                                    <FormLabel htmlFor='password'>Enter Password</FormLabel>
                                    <FormInput name="password" type='password' onChange={handlePasswordChanged.bind()} required />
                                    <FormLabel htmlFor='name'>Enter your name</FormLabel>
                                    <FormInput name="name" type='text' onChange={handleNameChanged.bind()} required />
                                    <FormLabel htmlFor='email'>Enter your email address</FormLabel>
                                    <FormInput name="email" type='email' onChange={handleEmailChanged.bind()} required />
                                    <FormLabel htmlFor='rating'>Rate Us</FormLabel>
                                    {/* <FormInput name="rating" type='text' onChange={handleRatingChanged.bind()} /> */}
                                    <div className="rating-container">
                                        <a id="ratingOne" onClick={handleOneRatingChanged.bind()}>1</a>
                                        <a id="ratingTwo" onClick={handleTwoRatingChanged.bind()}>2</a>
                                        <a id="ratingThree" onClick={handleThreeRatingChanged.bind()}>3</a>
                                        <a id="ratingFour" onClick={handleFourRatingChanged.bind()}>4</a>
                                        <a id="ratingFive" onClick={handleFiveRatingChanged.bind()}>5</a>
                                    </div>
                                    <FormLabel htmlFor='comment'>Comment</FormLabel>
                                    {/* <FormTextArea name="comment" type='text' onChange={handleRatingChanged.bind()} required></FormTextArea> */}
                                    <textarea className="client-comment" name="comment" type='text' rows="5" onChange={handleCommentChanged.bind()} placeholder="Type your comment"></textarea>
                                    <FormButton type='submit'>Generate Password</FormButton>
                                </Form>
                            </FormContent>
                        </FormWrap>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt="subscribe" />
                        </ImgWrap>
                    </Column2>
                </SubscribeRow>
            </SubscribeWrapper>

            <SupportUsContainer>
                <SupportUsWrapper>
                    <SuportUsLink href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><SupportUsImg src={BMCIcon} /></SuportUsLink>
                </SupportUsWrapper>
            </SupportUsContainer>
        </Container>
    )
}

export default PasswordGenerator