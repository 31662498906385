import React from 'react';
import { Container, ContactWrapper, ContactRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormTextArea, FormButton, ImgWrap, Img } from './ContactElements';
import img from '../../images/contact.svg';
import fire from '../utilities/firebase';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = ({imgStart}) => {
    
     var state = {
        customer: {
          name : '',
          email : '',
          message : '',
          date: new Date().toString()
        }
      }

    const handleNameChanged = event => {
        var customer        = state.customer;
        customer.name  = event.target.value;
        state.customer = customer;
      }
    
    const  handleEmailChanged = event => {
        var customer      = state.customer;
        customer.email = event.target.value;
        state.customer = customer;
      }
    
    const  handleMessageChanged = event => {
        var customer    = state.customer;
        customer.message = event.target.value;
        state.customer = customer;
      }

    const handleSubmit = async (event) => {
        event.preventDefault();
        toast.configure();
        var formData = state.customer;
        fire.database().ref('contact').orderByKey().limitToLast(100);
        await fire.database().ref('contact').push(formData);
        document.getElementById("contact-form").reset();
        toast.success('Thanks for the message. We will get back to you soon!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
    };

    return (
            <Container id='contact'>
                <ContactWrapper>
                    <ContactRow imgStart={imgStart}>
                        <Column1>
                            <FormWrap>
                                <FormContent>
                                    <Form onSubmit={handleSubmit} id="contact-form">
                                        <FormH1>Contact Us</FormH1>
                                        <FormP>Please contact us if you have any suggestion or query.</FormP>
                                        <FormLabel htmlFor='name'>Name</FormLabel>
                                        <FormInput name="name" type='text' onChange={handleNameChanged.bind()} required />
                                        <FormLabel htmlFor='email'>Email</FormLabel>
                                        <FormInput name="email" type='email' onChange={handleEmailChanged.bind()} required />
                                        <FormLabel htmlFor='message'>Message</FormLabel>
                                        <FormTextArea name="message" type='text' onChange={handleMessageChanged.bind()} required />
                                        <FormButton type='submit'>Submit</FormButton>
                                    </Form>
                                </FormContent>
                            </FormWrap>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt="contact" />
                            </ImgWrap>
                        </Column2>
                    </ContactRow>
                </ContactWrapper>
            </Container>
    )
}

export default Contact
