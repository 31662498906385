import React, { useState } from 'react';
import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormButton, ImgWrap, Img, SupportUsContainer, SupportUsWrapper, SuportUsLink, FormSelect, Foption } from './LoveForm';
import img1 from '../../images/couple.svg';
import img2 from '../../images/chilling.svg';
import img3 from '../../images/party.svg';
import fire from '../utilities/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaHeart } from 'react-icons/fa';
import { FaArrowCircleDown } from 'react-icons/fa';
import { datePlannerQuestions, formQuestions } from '../utilities/LoveData';
import ConfettiExplosion from 'react-confetti-explosion';

const LoveForm = ({ id, data, imgStart }) => {
    const [showConfetti, setShowConfetti] = useState(true);

    var state = {
        customer: {
            q1: '',
            q2: '',
            q3: '',
            q4: '',
            q5: '',
            q6: '',
            q7: '',
            q8: '',
            q9: '',
            q10: '',
            q11: '',
            q12: '',
            formsubmitteddate: new Date().toString()
        },
        datePlanner: {
            q1: '',
            q2: 'Indoor',
            q3: 'Vegetarian',
            q4: '',
            date: '',
            formsubmitteddate: new Date().toString()
        }
    }

    //Question form properties
    const handleQuestion1Changed = event => {
        var customer = state.customer;
        customer.q1 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion2Changed = event => {
        var customer = state.customer;
        customer.q2 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion3Changed = event => {
        var customer = state.customer;
        customer.q3 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion4Changed = event => {
        var customer = state.customer;
        customer.q4 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion5Changed = event => {
        var customer = state.customer;
        customer.q5 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion6Changed = event => {
        var customer = state.customer;
        customer.q6 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion7Changed = event => {
        var customer = state.customer;
        customer.q7 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion8Changed = event => {
        var customer = state.customer;
        customer.q8 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion9Changed = event => {
        var customer = state.customer;
        customer.q9 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion10Changed = event => {
        var customer = state.customer;
        customer.q10 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion11Changed = event => {
        var customer = state.customer;
        customer.q11 = event.target.value;
        state.customer = customer;
    }

    const handleQuestion12Changed = event => {
        var customer = state.customer;
        customer.q12 = event.target.value;
        state.customer = customer;
    }


    const handleOneRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '1';
        state.customer = customer;
        var ratingOne = document.getElementById('ratingOne');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingOne) {
            ratingOne.classList.add('is-active');
        }
    }

    const handleTwoRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '2t';
        state.customer = customer;
        var ratingTwo = document.getElementById('ratingTwo');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingTwo) {
            ratingTwo.classList.add('is-active');
        }
    }

    const handleThreeRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '3';
        state.customer = customer;
        var ratingThree = document.getElementById('ratingThree');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingThree) {
            ratingThree.classList.add('is-active');
        }
    }

    const handleFourRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '4';
        state.customer = customer;
        var ratingFour = document.getElementById('ratingFour');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFour) {
            ratingFour.classList.add('is-active');
        }
    }

    const handleFiveRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '5';
        state.customer = customer;
        var ratingFive = document.getElementById('ratingFive');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFive) {
            ratingFive.classList.add('is-active');
        }
    }

    //Date Planner form properties
    const handleQ1Changed = event => {
        var customer = state.datePlanner;
        customer.q1 = event.target.value;
        state.datePlanner = customer;
    }

    const handleQ2Changed = event => {
        var customer = state.datePlanner;
        customer.q2 = event.target.value;
        state.datePlanner = customer;
    }

    const handleQ3Changed = event => {
        var customer = state.datePlanner;
        customer.q3 = event.target.value;
        state.datePlanner = customer;
    }

    const handleQ4Changed = event => {
        var customer = state.datePlanner;
        customer.q4 = event.target.value;
        state.datePlanner = customer;
    }

    const handleQ5Changed = event => {
        var customer = state.datePlanner;
        customer.q5 = event.target.value;
        state.datePlanner = customer;
    }


    const handleQuestionSubmit = event => {
        setShowConfetti(false);
        toast.warning('Hold On, almost done', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        var formData = state.customer;
        fire.firestore().collection('questionformdata').doc(id).set(formData).then((result) => {
            toast.dismiss();
            setShowConfetti(true);
            document.getElementById("question-form").reset();
            var elements = document.getElementsByClassName('is-active');
            while (elements.length > 0) {
                elements[0].classList.remove('is-active');
            }
            toast.success('Thank you so much for answering my questions', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        },
            (error) => {
                toast.error('Something went wrong, please try again after sometime', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    };

    const handleDatePlannerSubmit = event => {
        setShowConfetti(false);
        toast.warning('Hold On, almost done', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        var formData = state.datePlanner;
        formData['foodType'] = formData['foodType'] ? formData['foodType'] : 'Vegetarian';
        formData['location'] = formData['location'] ? formData['location'] : 'Indoor';
        fire.firestore().collection('dateplannerdata').doc(id).set(formData).then(() => {
            toast.dismiss();
            setShowConfetti(true);
            document.getElementById("date-planner-form").reset();
            var elements = document.getElementsByClassName('is-active');
            while (elements.length > 0) {
                elements[0].classList.remove('is-active');
            }
            toast.success('Thank you so much. Wait for surprises!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        },
            (error) => {
                toast.error('Something went wrong, please try again after sometime', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    }

    const handleYes = event => {
        setShowConfetti(false);
        toast.warning('Hold On, almost done', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        const itsAYes = {
            name: id,
            response: 'It\'s a yes!',
            date: new Date().toString()
        }
        fire.firestore().collection('itsayes').doc(id).set(itsAYes).then(() => {
            toast.dismiss();
            setShowConfetti(true);
            toast.success('It\'s the best thing happened to me in a very long time. Thank you so much.', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        },
            (error) => {
                toast.error('Something went wrong, please try again after sometime', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    }
    // const { width, height } = useWindowSize();

    return (
        <>
            {data &&
                <Container id='love-form'>
                    <ToastContainer />
                    {showConfetti && <ConfettiExplosion zIndex={1000} />}
                    <SubscribeWrapper>
                        <SubscribeRow imgStart={false}>
                            <Column1>
                                <FormH1>Hi there,</FormH1>
                                <FormP>My heart longs for the moment you accept my Valentine's date proposal, igniting a flame of love that dances in the depths of our souls. I will be thankful for all the laughs and moments we will have. Don't forget to fill out our date planner, Can't wait to see what mischief we get into on our Valentine's adventure!
                                </FormP>
                            </Column1>
                            <Column2>
                                <ImgWrap>
                                    <Img src={img1} alt="subscribe" />
                                </ImgWrap>
                            </Column2>
                        </SubscribeRow>
                        <SubscribeRow imgStart={true}>
                            <Column1>
                                <FormWrap>
                                    <FormContent>
                                        <Form onSubmit={handleQuestionSubmit} id="question-form">
                                            <FormH1>Let's get to know you</FormH1>
                                            <FormP>Don't be afraid to open your heart! let yourself be loved as deeply as you love.</FormP>

                                            <FormLabel htmlFor='q1'>{formQuestions.q1}</FormLabel>
                                            <textarea className="client-comment" name="q1" type='text' rows="3" onChange={handleQuestion1Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q2'>{formQuestions.q2}</FormLabel>
                                            <textarea className="client-comment" name="q2" type='text' rows="3" onChange={handleQuestion2Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q3'>{formQuestions.q3}</FormLabel>
                                            <textarea className="client-comment" name="q3" type='text' rows="3" onChange={handleQuestion3Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q4'>{formQuestions.q4}</FormLabel>
                                            <textarea className="client-comment" name="q4" type='text' rows="3" onChange={handleQuestion4Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q5'>{formQuestions.q5}</FormLabel>
                                            <textarea className="client-comment" name="q5" type='text' rows="3" onChange={handleQuestion5Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q6'>{formQuestions.q6}</FormLabel>
                                            <textarea className="client-comment" name="q6" type='text' rows="3" onChange={handleQuestion6Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q7'>{formQuestions.q7}</FormLabel>
                                            <textarea className="client-comment" name="q7" type='text' rows="3" onChange={handleQuestion7Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q8'>{formQuestions.q8}</FormLabel>
                                            <textarea className="client-comment" name="q8" type='text' rows="3" onChange={handleQuestion8Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q9'>{formQuestions.q9}</FormLabel>
                                            <textarea className="client-comment" name="q9" type='text' rows="3" onChange={handleQuestion9Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q10'>{formQuestions.q10}</FormLabel>
                                            <textarea className="client-comment" name="q10" type='text' rows="3" onChange={handleQuestion10Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q11'>{formQuestions.q11}</FormLabel>
                                            <textarea className="client-comment" name="q11" type='text' rows="3" onChange={handleQuestion11Changed.bind()} placeholder="Type your answer"></textarea>

                                            <FormLabel htmlFor='q12'>{formQuestions.q12}</FormLabel>
                                            <textarea className="client-comment" name="q12" type='text' rows="3" onChange={handleQuestion12Changed.bind()} placeholder="Type your answer"></textarea>

                                            {/* <FormLabel htmlFor='rating'>How good we will look together?</FormLabel>
                                            <div className="rating-container">
                                                <a id="ratingOne" onClick={handleOneRatingChanged.bind()}>1</a>
                                                <a id="ratingTwo" onClick={handleTwoRatingChanged.bind()}>2</a>
                                                <a id="ratingThree" onClick={handleThreeRatingChanged.bind()}>3</a>
                                                <a id="ratingFour" onClick={handleFourRatingChanged.bind()}>4</a>
                                                <a id="ratingFive" onClick={handleFiveRatingChanged.bind()}>5</a>
                                            </div> */}

                                            <FormButton type='submit'>Send</FormButton>

                                        </Form>
                                    </FormContent>
                                </FormWrap>
                            </Column1>
                            <Column2>
                                <ImgWrap>
                                    <Img src={img2} alt="subscribe" />
                                </ImgWrap>
                            </Column2>
                        </SubscribeRow>

                        <SubscribeRow imgStart={false}>
                            <Column1>
                                <FormWrap>
                                    <FormContent>
                                        <Form onSubmit={handleDatePlannerSubmit} id="date-planner-form">
                                            <FormH1>Date Planner</FormH1>
                                            <FormP>I am well aware of your busy schedule but don't you think, The best thing you could give someone is a Chance.
                                                Lets plan our date.
                                            </FormP>

                                            <FormLabel htmlFor='city'>{datePlannerQuestions.q1}</FormLabel>
                                            <FormInput name="city" type='text' onChange={handleQ1Changed.bind()} required />

                                            <FormLabel htmlFor='locationtype'>{datePlannerQuestions.q2}</FormLabel>
                                            <FormSelect name="locationtype" onChange={handleQ2Changed.bind()}>
                                                <Foption name="indoor">Indoor</Foption>
                                                <Foption name="outdoor">Outdoor</Foption>
                                            </FormSelect>

                                            <FormLabel htmlFor='food'>{datePlannerQuestions.q3}</FormLabel>
                                            <FormSelect name="food" onChange={handleQ3Changed.bind()}>
                                                <Foption name="veg">Vegetarian</Foption>
                                                <Foption name="non-veg">Non-Vegetarian</Foption>
                                            </FormSelect>

                                            <FormLabel htmlFor='date'>{datePlannerQuestions.q4}</FormLabel>
                                            <FormInput name="date" type='text' onChange={handleQ4Changed.bind()} required />

                                            <FormLabel htmlFor='suggestions'>{datePlannerQuestions.q5}</FormLabel>
                                            <FormInput name="suggestions" type='text' onChange={handleQ5Changed.bind()} required />

                                            <FormLabel htmlFor='note'>Leave rest to me. It's going to be a memorable date.</FormLabel>

                                            {/* <FormLabel htmlFor='rating'>Rate Us</FormLabel>

                                         <div className="rating-container">
                                            <a id="ratingOne" onClick={handleOneRatingChanged.bind()}>1</a>
                                            <a id="ratingTwo" onClick={handleTwoRatingChanged.bind()}>2</a>
                                            <a id="ratingThree" onClick={handleThreeRatingChanged.bind()}>3</a>
                                            <a id="ratingFour" onClick={handleFourRatingChanged.bind()}>4</a>
                                            <a id="ratingFive" onClick={handleFiveRatingChanged.bind()}>5</a>
                                        </div> */}



                                            <FormButton type='submit'>Plan</FormButton>
                                        </Form>
                                    </FormContent>
                                </FormWrap>
                            </Column1>
                            <Column2>
                                <ImgWrap>
                                    <Img src={img3} alt="subscribe" />
                                </ImgWrap>
                            </Column2>
                        </SubscribeRow>

                    </SubscribeWrapper>
                    <div class="waiting-for-yes">Let's give us a chance, waiting for your yes</div>
                    <div class="waiting-for-yes"><FaArrowCircleDown /></div>


                    <SupportUsContainer onClick={handleYes}>
                        <SupportUsWrapper>
                            <SuportUsLink target="_blank"><span class="its-a-yes">It's a yes</span> <FaHeart /></SuportUsLink>
                        </SupportUsWrapper>
                    </SupportUsContainer>
                </Container>
            }</>
    )
}

export default LoveForm