import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, SidebarRouteLink} from './SidebarElements';
import { useState } from 'react';

const Sidebar = ({ isOpen, toggle}) => {
    const [loggedIn, setLoggedIn] = useState(true); 

    const goToPayments = () => {
        var url = window.location.protocol + "//" + window.location.host+'/payments';
        window.location = url;
    }
    const goToPingenerator = () => {
        var url = window.location.protocol + "//" + window.location.host+'/pingenerator';
        window.location = url;
    }
    const goToLogin = () => {
        var url = window.location.protocol + "//" + window.location.host+'/login';
        window.location = url;
    }

    const logOut = () => {
        setLoggedIn(false);
        toggle();
    }
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>About</SidebarLink>
                    <SidebarLink to="products" onClick={toggle}>Products</SidebarLink>
                    <SidebarLink to="suggestion" onClick={toggle}>Hire</SidebarLink>
                    <SidebarLink to="upcoming" onClick={toggle}>Upcoming</SidebarLink>
                    <SidebarLink onClick={goToPayments}>Payment</SidebarLink>
                    <SidebarLink onClick={goToPingenerator}>Generate Pin</SidebarLink>
                    <SidebarLink to="support" onClick={toggle}>Support Us</SidebarLink>  
                    <SidebarLink to="contact" onClick={toggle}>Contact Us</SidebarLink>
                    <SidebarLink to="subscribe" onClick={toggle}>Subscribe</SidebarLink>
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute onClick={logOut}>Logout</SidebarRoute>
                </SideBtnWrap> */}
                {/* <SideBtnWrap>
                    {!loggedIn ? <SidebarRoute onClick={goToLogin}>Login</SidebarRoute> :
                        <SidebarRoute onClick={logOut}>Logout</SidebarRoute>
                    }
                </SideBtnWrap> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
 