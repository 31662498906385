import React, {useState, Fragment} from 'react'
import LittleNavbar from '../components/LittleNavbar'
import LittleSidebar from '../components/LittleSidebar';
import PasswordGenerator from '../components/PasswordGenerator';
import Footer from '../components/Footer';

const PasswordGeneratorPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const hideSubscribe = false;
    return (
        <Fragment>
            <LittleSidebar isOpen={isOpen} toggle={toggle} />
            <LittleNavbar toggle={toggle} hideSubscribe={hideSubscribe} />
            <PasswordGenerator />
            <Footer />
        </Fragment>
    )
}

export default PasswordGeneratorPage;
