import React from 'react';
import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormTextArea, FormButton, ImgWrap, Img, SupportUsContainer, SupportUsWrapper, SuportUsLink, SupportUsImg } from './Payment';
import img from '../../images/payments.svg';
import fire from '../utilities/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Payment = ({ imgStart }) => {

    const [displayPaymentDetails, setPaymentDetailsStatus] = React.useState(false);
    const [clientName, setClientName] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const [clientDesc, setClientDesc] = React.useState('');
    const [clientAmount, setClientAmount] = React.useState(0);
    const [clientInvoiceUrl, setClientInvoiceUrl] = React.useState('');
    const [couponCode, setCouponCode] = React.useState('');
    const [couponDesc, setCouponDesc] = React.useState('');
    const [originalAmount, setOriginalAmount] = React.useState(0);
    const [fetchButtonText, setFetchButtonText] = React.useState('Fetch Details');
    const [applyButtonText, setApplyButtonText] = React.useState('Apply');

    var state = {
        customer: {
            clientId: '',
            rating: '',
            comment: '',
            date: new Date().toString()
        }
    }

    // fire.firestore().collection('payments').doc(clientId).get().then((result) => {
    //     if (result && result.data()) {
    //         var data = result.data();
    //         var clientName = data.name;
    //         setName(clientName);
    //     }
    // })

    const handleCouponCodeChanged = event => {
        setCouponCode(event.target.value);
    }

    const applyCouponCode = event => {
        setApplyButtonText('Please Wait...');
        const couponCodeData = axios.post('https://us-central1-dockdevelopers-27abb.cloudfunctions.net/processCouponcode', {
            "data": { clientId: clientId, couponCode: couponCode }
        });
        couponCodeData.then((res) => {
            setApplyButtonText('Apply');
            if (res && res.data && res.data.result && res.data.result.paymentDetails && res.data.result.paymentDetails.applied) {
                var details = res.data.result.paymentDetails;
                toast.dismiss();
                document.getElementById("payment-form").reset();
                setClientAmount(details.finalAmount);
                setCouponDesc(details.couponDesc);
            }
            else {
                toast.dismiss();
                setClientAmount(originalAmount);
                setCouponDesc('Invalid Coupon Code');
                toast.error('You\'ve entered a wrong coupon code. Please enter valid coupon code.', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }
        }).catch((error) => {
            setApplyButtonText('Apply');
            console.log(error);
            toast.dismiss();
            setClientAmount(originalAmount);
            setCouponDesc('');
            toast.error('We are facing some technical issue. Please try again after sometime or drop a mail to us at business@dockdevelopers.com', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        })
    }

    const handleOneRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '1';
        state.customer = customer;
        var ratingOne = document.getElementById('ratingOne');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingOne) {
            ratingOne.classList.add('is-active');
        }
    }

    const handleTwoRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '2t';
        state.customer = customer;
        var ratingTwo = document.getElementById('ratingTwo');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingTwo) {
            ratingTwo.classList.add('is-active');
        }
    }

    const handleThreeRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '3';
        state.customer = customer;
        var ratingThree = document.getElementById('ratingThree');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingThree) {
            ratingThree.classList.add('is-active');
        }
    }

    const handleFourRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '4';
        state.customer = customer;
        var ratingFour = document.getElementById('ratingFour');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFour) {
            ratingFour.classList.add('is-active');
        }
    }

    const handleFiveRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '5';
        state.customer = customer;
        var ratingFive = document.getElementById('ratingFive');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFive) {
            ratingFive.classList.add('is-active');
        }
    }

    const handleUserNameChanged = event => {
        var customer = state.customer;
        customer.clientId = event.target.value;
        state.customer = customer;
        setClientId(event.target.value);
    }

    const handlePayment = event => {
        toast.warning('Please wait, we are processing your request', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        var data = {
            clientId: clientId,
            rating: state.customer.rating,
            comment: state.customer.comment,
            date: state.customer.date
        };
        // if(couponDesc)
        // fire.firestore().collection('payments').doc(state.customer.clientId).get().then((doc) => {
        //     if (doc && doc.data()) {
        var payData = { clientId: clientId, name: clientName, desc: clientDesc, amount: clientAmount, couponCode: couponCode, couponDesc: couponDesc };
        const stripeCheckout = axios.post('https://us-central1-dockdevelopers-27abb.cloudfunctions.net/createStripeCheckout', {
            "data": payData
        });
        stripeCheckout.then((result) => {
            if (result && result.data && result.data.result && result.data.result.url) {
                data.originalAmount = originalAmount;
                data.amountAfterCoupon = result.data.result.amount;
                data.status = 'Attempted';
                data.stripeId = result.data.result.id;
                data.couponCode = couponCode;
                var stripeCheckoutUrl = result.data.result.url;
                data.stripeCheckoutUrl = stripeCheckoutUrl;
                fire.firestore().collection('attemptedpayments').add(data).then((res) => {
                    if (res) {
                        //Redirect user to stripe checkout url
                        toast.dismiss();
                        cookies.set('dd_client_pm_id', result.data.result.id, { path: '/' });
                        window.location.href = stripeCheckoutUrl;
                    }
                })
            }
        }).catch((error) => {
            console.log(error);
            toast.dismiss();
            toast.error('We are facing some technical issue. Please try again after sometime or drop a mail to us at business@dockdevelopers.com', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        })
        //     }
        //     else {
        //         toast.dismiss();
        //         toast.error('We didn\'t find any record corresponding to your clientid. Please drop a mail to us at business@dockdevelopers.com', {
        //             position: "top-center",
        //             autoClose: 3000,
        //             hideProgressBar: true,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined
        //         });
        //     }
        // }).catch(() => {
        //     toast.dismiss();
        //     toast.error('We are facing some technical issue. Please try again after sometime or drop a mail to us at business@dockdevelopers.com', {
        //         position: "top-center",
        //         autoClose: 3000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: false,
        //         progress: undefined
        //     });
        // })

    }

    const fetchDetails = event => {
        setFetchButtonText('Please Wait...')
        toast.warning('Please wait, we are processing your request', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        const fetchPaymentDetails = axios.post('https://us-central1-dockdevelopers-27abb.cloudfunctions.net/paymentDetails', {
            "data": { clientId: clientId.toString() }
        });
        fetchPaymentDetails.then((res) => {
            if (res && res.data && res.data.result && res.data.result.paymentDetails && res.data.result.paymentDetails.clientId) {
                var details = res.data.result.paymentDetails;
                toast.dismiss();
                if(details.isSettled) {
                    toast.success('We have already received your payment. Thank you for trusting us. Please drop a mail to us at business@dockdevelopers.com for any query.', {
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                    setFetchButtonText('Fetch Details');
                    return;
                }
                setClientId(details.clientId);
                setClientName(details.name);
                setClientDesc(details.description);
                setClientAmount(details.amount);
                setClientInvoiceUrl(details.invoiceUrl);
                setPaymentDetailsStatus(true);
                setOriginalAmount(details.amount);
            }
            else {
                setFetchButtonText('Fetch Details');
                toast.dismiss();
                toast.error('We didn\'t find any record corresponding to your Client ID. Please drop a mail to us at business@dockdevelopers.com', {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }
        }).catch((error) => {
            setFetchButtonText('Fetch Details');
            console.log(error);
            toast.dismiss();
            toast.error('We are facing some technical issue. Please try again after sometime or drop a mail to us at business@dockdevelopers.com', {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        })
    }

    return (

        <Container id='clientpasswordgenerator'>
            <ToastContainer />
            <SubscribeWrapper>
                <SubscribeRow imgStart={imgStart}>
                    <Column1>
                        <ImgWrap>
                            <Img src={img} alt="subscribe" />
                        </ImgWrap>
                    </Column1>
                    <Column2>
                        <FormWrap>
                            <FormContent>
                                {!displayPaymentDetails && <Form onSubmit={fetchDetails} id="payment-form">
                                    <FormH1>Payments</FormH1>
                                    <FormP>Thanks for giving us an opportunity to serve you. We hope, you are satisfied with our work.<br /><br />Please enter your Client ID(mentioned in release mail) to fetch your payment details. </FormP>
                                    <FormLabel htmlFor='clientId'>Enter Client Id</FormLabel>
                                    <FormInput className="bottom-13-margin" name="clientId" type='text' onChange={handleUserNameChanged.bind()} required />
                                    <FormButton type='submit'>{fetchButtonText}</FormButton><br />
                                    <FormLabel>Contact us if you need any help, we will be more than happy to help you out in any possible way.</FormLabel>
                                </Form>}
                                {displayPaymentDetails && <Form onSubmit={handlePayment} id="payment-form">
                                    <FormH1>Invoice</FormH1>
                                    <FormLabel><mark>Please check all the details mentioned in the invoice carefully.</mark></FormLabel>
                                    <FormLabel>
                                        If you find any discrepancy, we recommend you, <span className="italic-font">not to proceed with the payment</span>.<br />
                                        Please drop a mail to us at business@dockdevelopers.com</FormLabel>
                                    <FormLabel> If your're happy and want to proceed with the payment, please take a moment to rate us and add your comments or suggestions to help us improve.</FormLabel>
                                    <FormP>Client ID: {clientId}</FormP>
                                    <FormP>Client Name: {clientName}</FormP>
                                    <FormP>Payment For: {clientDesc}</FormP>
                                    <FormP>Total Amount: &#x20B9; {originalAmount}</FormP>
                                    <FormP>Invoice Link: <a href={clientInvoiceUrl} target="_blank" className="openlinks">Open</a>
                                        {/* <a href={clientInvoiceUrl} target="_blank" className="copylinks">Copy</a> */}
                                    </FormP>
                                    <FormP htmlFor='rating' className="bottom-8-margin">Rate Us</FormP>
                                    <div className="rating-container bottom-10-margin">
                                        <a id="ratingOne" onClick={handleOneRatingChanged.bind()}>1</a>
                                        <a id="ratingTwo" onClick={handleTwoRatingChanged.bind()}>2</a>
                                        <a id="ratingThree" onClick={handleThreeRatingChanged.bind()}>3</a>
                                        <a id="ratingFour" onClick={handleFourRatingChanged.bind()}>4</a>
                                        <a id="ratingFive" onClick={handleFiveRatingChanged.bind()}>5</a>
                                    </div>
                                    <FormP className="bottom-8-margin">Coupon Code:</FormP>
                                    <div className="coupon-container">
                                        <FormInput name="couponcode" className="bottom-13-margin" type='text' onChange={handleCouponCodeChanged.bind()} />
                                        <a className="applyCouponBtn" onClick={applyCouponCode.bind()}>{applyButtonText}</a>
                                    </div>
                                    {couponDesc && <FormP className="couponDesc">{couponDesc}</FormP>}
                                    {/* <FormLabel htmlFor='comment'>Comment</FormLabel>
                                    <textarea className="client-comment" name="comment" type='text' rows="5" onChange={handleCommentChanged.bind()} placeholder="Type your comment"></textarea> */}
                                    <FormButton type='submit' className="bottom-8-margin">Pay &#x20B9; {clientAmount}</FormButton>
                                    <FormLabel>Contact us if you need any help, we will be more than happy to help you out in any possible way.</FormLabel>
                                </Form>}
                            </FormContent>
                        </FormWrap>
                    </Column2>
                </SubscribeRow>
            </SubscribeWrapper>

            <SupportUsContainer>
                <SupportUsWrapper>
                    <SuportUsLink href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><SupportUsImg src={BMCIcon} /></SuportUsLink>
                </SupportUsWrapper>
            </SupportUsContainer>
        </Container>
    )
}

export default Payment