export const datePlannerQuestions =
{
    q1: 'Which city would you prefer?',
    q2: 'What kind of activity would you love?',
    q3: 'What kind of food you will enjoy most?',
    q4: 'On which date, are you comfortable?',
    q5: 'What special I can do for you?',
};

export const formQuestions =
{
    q1: 'What kind of music do you like to listen to? Mention your 3 most favourite songs.',
    q2: 'What was your favorite food growing up?',
    q3: 'What\'s your favorite meal now?',
    q4: 'What was your favorite school subject growing up?',
    q5: 'What do you think about me?',
    q6: 'What\'s your definition of romance?',
    q7: 'Would you rather be loved, respected, or admired?',
    q8: 'Is there a personality trait that always ticks you off in a person?',
    q9: 'Do you have a bucket list? If yes, what\'s on top?',
    q10: 'Is there a type of outfit or clothes you find sexy that you\'d like to see me in?',
    q11: 'Do you believe in God?',
    q12: 'Do you love pets? If yes, which is your favourite pet animal and why?',
};