import React, { useState } from 'react';
import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, CopyInputContainer, FormButton, ImgWrap, Img, SupportUsContainer, SupportUsWrapper, SuportUsLink, SupportUsImg, LinkArea, CopyButton, ItsAYesContainer } from './LoveDashboard';
import img1 from '../../images/feedback.svg';
import img2 from '../../images/love.svg';
import img3 from '../../images/spread-love.svg';
import img4 from '../../images/date-night.svg';
import fire from '../utilities/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';
import { FaCopy } from 'react-icons/fa';
import Collapsible from 'react-collapsible';
import { datePlannerQuestions, formQuestions } from '../utilities/LoveData';
import { useEffect } from 'react';
import { FaHeart } from 'react-icons/fa';
import ConfettiExplosion from 'react-confetti-explosion';

const LoveDashboard = ({ id, data }) => {
    const link = `https://dockdevelopers.com/love/${id}`;
    const [showConfetti, setShowConfetti] = useState(true);
    const [datePlannerAnswers, setDatePlannerAnswers] = useState();
    const [formAnswers, setFormAnswers] = useState();
    const [itsAYesAnswer, setItsAYesAnswer] = useState(false);
    var state = {
        customer: {
            feedback: '',
            rating: ''
        },
    }

    useEffect(() => {
        const formDocRef = fire.firestore().collection('questionformdata').doc(id);
        const datePlannerDocRef = fire.firestore().collection('dateplannerdata').doc(id);
        const itsAYesDocRef = fire.firestore().collection('itsayes').doc(id);
        formDocRef.get().then((doc) => {
            if (doc.data()) {
                setFormAnswers(doc.data());
            }
        }
        );
        datePlannerDocRef.get().then((doc) => {
            if (doc.data()) {
                setDatePlannerAnswers(doc.data());
            }
        }
        );
        itsAYesDocRef.get().then((doc) => {
            if (doc.data()) {
                setItsAYesAnswer(doc.data());
            }
        }
        );
    }, [id]);

    const copyLink = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(link).then(function () {
            toast.success('Copied!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }, function (err) {
            toast.error('Something went wrong, please try again after sometime', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        });
    }

    const handleFeedbackChanged = event => {
        var customer = state.customer;
        customer.feedback = event.target.value;
        state.customer = customer;
    }


    const handleOneRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '1';
        state.customer = customer;
        var ratingOne = document.getElementById('ratingOne');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingOne) {
            ratingOne.classList.add('is-active');
        }
    }

    const handleTwoRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '2t';
        state.customer = customer;
        var ratingTwo = document.getElementById('ratingTwo');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingTwo) {
            ratingTwo.classList.add('is-active');
        }
    }

    const handleThreeRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '3';
        state.customer = customer;
        var ratingThree = document.getElementById('ratingThree');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingThree) {
            ratingThree.classList.add('is-active');
        }
    }

    const handleFourRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '4';
        state.customer = customer;
        var ratingFour = document.getElementById('ratingFour');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFour) {
            ratingFour.classList.add('is-active');
        }
    }

    const handleFiveRatingChanged = event => {
        var customer = state.customer;
        customer.rating = '5';
        state.customer = customer;
        var ratingFive = document.getElementById('ratingFive');
        var elements = document.getElementsByClassName('is-active');
        while (elements.length > 0) {
            elements[0].classList.remove('is-active');
        }
        if (ratingFive) {
            ratingFive.classList.add('is-active');
        }
    }
    const handleSubmit = event => {
        setShowConfetti(false);
        toast.warning('Hold On, almost done', {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
        event.preventDefault();
        toast.configure();
        var formData = state.customer;
        fire.firestore().collection('proposalsfeedback').doc(id).set(formData).then(() => {
            setShowConfetti(true);
            toast.dismiss();
            document.getElementById("feedback-form").reset();
            var elements = document.getElementsByClassName('is-active');
            while (elements.length > 0) {
                elements[0].classList.remove('is-active');
            }
            toast.success('Thanks for sharing your feeback!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        },
            (error) => {
                toast.error('Something went wrong, please try again after sometime', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    }

    return (
        <Container id='love-form'>
            <ToastContainer />
              {showConfetti && <ConfettiExplosion zIndex={1000}  />}
            <SubscribeWrapper>
                {data &&
                    <SubscribeRow imgStart={true}>
                        <Column1>
                            <FormWrap>
                                <FormContent>
                                    <Form id="details-form">
                                        <FormH1 style={{ fontSize: '24px' }}>Hello, {data?.name}</FormH1>
                                        <FormP>Thank you for choosing DockDevelopers!<br></br>
                                            Suggest expressing your feelings to your crush by sharing the link with them, and remember to cherish their company.
                                        </FormP>

                                        <FormLabel htmlFor='name'>Name</FormLabel>
                                        <FormInput name="name" type='text' disabled={true} value={data?.name} />
                                        <FormLabel htmlFor='email'>Email</FormLabel>
                                        <FormInput name="email" type='text' disabled={true} value={data?.email} />
                                        <FormLabel htmlFor='vname'>Valentine's Name</FormLabel>
                                        <FormInput name="vname" type='text' disabled={true} value={data?.valentineName} />
                                        <FormLabel htmlFor='vname'>Link</FormLabel>
                                        <CopyInputContainer>
                                            <LinkArea>{link}</LinkArea>
                                            <CopyButton onClick={copyLink}><FaCopy fontSize={18} /></CopyButton>
                                        </CopyInputContainer>

                                        <FormButton><a href={link} target='_blank' rel="noreferrer" style={{ color: 'white' }}>Open</a></FormButton>
                                    </Form>
                                </FormContent>
                            </FormWrap>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img2} alt="feedback" />
                            </ImgWrap>
                        </Column2>
                    </SubscribeRow>
                }
                {itsAYesAnswer ? (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ItsAYesContainer>
                <SupportUsWrapper>
                    <SuportUsLink target="_blank"><span class="its-a-yes">It's a yes from {data.valentineName}</span> <FaHeart /></SuportUsLink>
                </SupportUsWrapper>
                </ItsAYesContainer>
                </div>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ItsAYesContainer style={{ backgroundColor: '#01bf71'}}>
                <SupportUsWrapper>
                    <SuportUsLink target="_blank"><span class="its-a-yes">Awaiting It's a yes response from {data.valentineName}</span></SuportUsLink>
                </SupportUsWrapper>
                </ItsAYesContainer>
                </div>
                )}
                <SubscribeRow imgStart={false}>
                    <Column1>
                        <FormWrap>
                            <FormContent>
                                <div>
                                    <FormH1>Questions Response</FormH1>
                                    <FormP>Thank you for choosing DockDevelopers!<br></br>
                                        Suggest expressing your feelings to your crush by sharing the link with them, and remember to cherish their company.
                                    </FormP>
                                    <FormP>Response received on: {formAnswers?.formsubmitteddate ? (<span style={{ color: '#01bf71' }}>{formAnswers?.formsubmitteddate}</span>) :
                                        (<span style={{ color: '#01bf71' }}>Yet to receive response from {data.valentineName}</span>)
                                    }</FormP>
                                    {Object.keys(formQuestions).map((item, index) => {
                                        return (
                                            <div style={{ marginBottom: '16px', cursor: 'pointer' }}>
                                                <Collapsible trigger={formQuestions[item]} key={index} triggerStyle={{ background: '#01bf71', padding: '8px', display: 'block', width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} >

                                                    {formAnswers && formAnswers[item] ? (
                                                        <p style={{
                                                            height: 'auto', background: '#f5f5f5',
                                                            padding: '12px',
                                                            color: 'black',
                                                            fontSize: '14px',
                                                            borderBottomLeftRadius: '10px', 
                                                            borderBottomRightRadius: '10px'
                                                        }}>{formAnswers[item]}</p>
                                                    ) : (
                                                        <p style={{
                                                            height: 'auto', background: '#f5f5f5',
                                                            padding: '12px',
                                                            color: 'black',
                                                            fontSize: '14px',
                                                            borderBottomLeftRadius: '10px', 
                                                            borderBottomRightRadius: '10px'
                                                        }}>No response yet</p>
                                                    )}
                                                </Collapsible>
                                            </div>
                                        )
                                    })}
                                </div>
                            </FormContent>
                        </FormWrap>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img3} alt="feedback" />
                        </ImgWrap>
                    </Column2>
                </SubscribeRow>
                <SubscribeRow imgStart={true}>
                    <Column1>
                        <FormWrap>
                            <FormContent>
                                <div>
                                    <FormH1>Date Planner Response</FormH1>
                                    <FormP>Thank you for choosing DockDevelopers!<br></br>
                                        Suggest expressing your feelings to your crush by sharing the link with them, and remember to cherish their company.
                                    </FormP>
                                    <FormP>Response received on: {datePlannerAnswers?.formsubmitteddate ? (<span style={{ color: '#01bf71' }}>{datePlannerAnswers?.formsubmitteddate}</span>) :
                                        (<span style={{ color: '#01bf71' }}>Yet to receive response from {data.valentineName}</span>)
                                    }</FormP>
                                    {Object.keys(datePlannerQuestions).map((item, index) => {
                                        return (
                                            <div style={{ marginBottom: '16px', cursor: 'pointer' }}>
                                                <Collapsible trigger={datePlannerQuestions[item]} key={index} triggerStyle={{ background: '#01bf71', padding: '8px', display: 'block', width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} >

                                                    {datePlannerAnswers && datePlannerAnswers[item] ? (
                                                        <p style={{
                                                            height: 'auto', background: '#f5f5f5',
                                                            padding: '12px',
                                                            color: 'black',
                                                            fontSize: '14px',
                                                            borderBottomLeftRadius: '10px', 
                                                            borderBottomRightRadius: '10px'
                                                        }}>{datePlannerAnswers[item]}</p>
                                                    ) : (
                                                        <p style={{
                                                            height: 'auto', background: '#f5f5f5',
                                                            padding: '12px',
                                                            color: 'black',
                                                            fontSize: '14px',
                                                            borderBottomLeftRadius: '10px', 
                                                            borderBottomRightRadius: '10px'
                                                        }}>No response yet</p>
                                                    )}
                                                </Collapsible>
                                            </div>
                                        )
                                    })}
                                </div>
                            </FormContent>
                        </FormWrap>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img4} alt="feedback" />
                        </ImgWrap>
                    </Column2>
                </SubscribeRow>
                <SubscribeRow imgStart={false}>
                    <Column1>
                        <FormWrap>
                            <FormContent>
                                <Form onSubmit={handleSubmit} id="feedback-form">
                                    <FormH1>Share your feedback</FormH1>
                                    <FormP>We value your opinion! Please take a moment to share your feedback with us. Your insights help us improve and serve you better. Thank you for your valuable input!
                                    </FormP>

                                    <FormLabel htmlFor='id'>Feedback</FormLabel>
                                    <textarea className="client-comment" name="feedback" type='text' rows="5" onChange={handleFeedbackChanged.bind()} placeholder="Type your message" required></textarea>

                                    <FormLabel htmlFor='rating'>Rate Us</FormLabel>
                                    <div className="rating-container">
                                        <a id="ratingOne" onClick={handleOneRatingChanged.bind()}>1</a>
                                        <a id="ratingTwo" onClick={handleTwoRatingChanged.bind()}>2</a>
                                        <a id="ratingThree" onClick={handleThreeRatingChanged.bind()}>3</a>
                                        <a id="ratingFour" onClick={handleFourRatingChanged.bind()}>4</a>
                                        <a id="ratingFive" onClick={handleFiveRatingChanged.bind()}>5</a>
                                    </div>

                                    <FormButton type='submit'>Submit</FormButton>
                                </Form>
                            </FormContent>
                        </FormWrap>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img1} alt="feedback" />
                        </ImgWrap>
                    </Column2>
                </SubscribeRow>

            </SubscribeWrapper>
            <SupportUsContainer>
                <SupportUsWrapper>
                    <SuportUsLink href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><SupportUsImg src={BMCIcon} /></SuportUsLink>
                </SupportUsWrapper>
            </SupportUsContainer>
        </Container>
    )
}

export default LoveDashboard