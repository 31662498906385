import React, {useState, Fragment} from 'react'
import HeroSection from '../components/HeroSection';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar';
import ProductSection from '../components/Products';
import Footer from '../components/Footer';
import InfoSection from '../components/InfoSection';
import { aboutObj, homeObjTwo, homeObjThree, subscribeObj, contactObj, supportObj, suggestionContractObj } from '../components/utilities/Data';
import UpcomingSection from '../components/Upcoming';
import Subscribe from '../components/Subscribe';
import Contact from '../components/Contact';
import SuggestionsContract from '../components/SuggestionsContract';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <Fragment>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection />
            <InfoSection {...aboutObj}/>
            <ProductSection />
            <SuggestionsContract {...suggestionContractObj} />
            <UpcomingSection />
            <InfoSection {...supportObj}/>
            <Contact {...contactObj}/>
            <Subscribe {...subscribeObj}/>
            <Footer />
        </Fragment>
    )
};

export default Home
