import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    color: #fff;
    background: #010606;
`;

export const SubscribeWrapper = styled.div`
    display: grid;
    z-index: 1;
    //height: 860px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const SubscribeRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2
    col1'` : `'col1 col2'` )};
    margin-top: 0%;


    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 0px;
    grid-area: col1;
    margin-top: 15%;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 0px;
    grid-area: col2;
`;

export const FormWrap = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 540px;
        padding-top: 0;
        padding-bottom: 60px;

        @media screen and (max-width: 400px) {
            height: 80%;
        }
`;

export const Icon = styled(Link)`
        margin-left: 32px;
        margi-top: 32px;
        text-decoration: none;
        color: #fff;
        font-weight: 700;
        font-size: 32px;

        @media screen and (max-width: 480px) {
            margin-left: 16px;
            margin-top: 8px;
        }
`;

export const FormContent = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 480px) {
            padding: 10px;
        }
`;

export const Form = styled.form`
        background: #010101;
        max-width: 400px;
        height: auto;
        width: 100%;
        z-index: 1;
        display: grid;
        margin: 0 auto;
        padding: 80px 32px;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
        margin-top: 2px;

        @media screen and (max-width: 400px) {
            padding: 32px 32px;
        }
`;

export const FormH1 = styled.h1`
        margin-bottom: 20px;
        color: #fff;
        font-size: 48px;
        font-weight: 600;
`;

export const FormP = styled.p`
        color: white;
        margin-bottom: 35px;
        font-size: 16px;
        line-height: 24px;
`;

export const FormLabel = styled.label`
        margin-bottom: 8px;
        font-size: 14px;
        color: #fff;
`;

export const FormInput = styled.input`
        padding: 16px 16px;
        margin-bottom: 16px;
        border: none;
        border-radius: 4px;
        background: white;
        color: black;
`;

export const LinkArea = styled.p`
        width: 230px;
        word-wrap: break-word;
        font-size: 13.5px;
`;

export const FormButton = styled.button`
        background: #01bf71;
        padding: 16px 0;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;


export const SupportUsContainer = styled.div`
    text-align: center;
    display: flex;
    margin-bottom: 0px;
    padding: 21px;
    cursor: pointer;
}
`;

export const ItsAYesContainer = styled.div`
    text-align: center;
    display: flex;
    margin-bottom: 0px;
    padding: 21px;
    cursor: pointer;
    background: red;
    width: 80%;
}
`;

export const SupportUsWrapper = styled.div`
    margin: auto;
`;
export const SuportUsLink = styled.a`
    text-decoration: none;
    font-size: 20px;
    color: white;
    padding: 20px;
    cursor: pointer;
`;

export const SupportUsImg = styled.img`
    width: 50%;
    border-radius: 22px;
`;

export const FormSelect = styled.select`
        padding: 16px 16px;
        margin-bottom: 32px;
        border: none;
        border-radius: 4px;
`;

export const CopyInputContainer = styled.div`
        display: flex;
        padding: 16px 16px;
        margin-bottom: 16px;
        border: none;
        border-radius: 4px;
        background: white;
        color: black;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
`;

export const CopyButton = styled.button`
        color: #01bf71;
        cursor: pointer;
        border: none;
        background: white;
`;