import React from 'react';
import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormTextArea, FormButton, ImgWrap, Img, SupportUsContainer, SupportUsWrapper, SuportUsLink, SupportUsImg } from './PageNotFound';
import img from '../../images/404.svg';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const PageNotFound = ({ imgStart }) => {
    

    return (

        <Container id='clientpasswordgenerator'>
            <ToastContainer />
            <SubscribeWrapper>
                <SubscribeRow imgStart={imgStart}>
                    <Column1>
                        <ImgWrap>
                            <Img src={img} alt="subscribe" />
                        </ImgWrap>
                    </Column1>
                    <Column2>
                    <h3>Oops, page not found.</h3><br />
                    <FormP>Visit our <a className="primaryText" href="/">homepage</a> to explore wide range of products and services offered by us.</FormP>
                    </Column2>
                </SubscribeRow>
            </SubscribeWrapper>

            <SupportUsContainer>
                <SupportUsWrapper>
                    <SuportUsLink href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><SupportUsImg src={BMCIcon} /></SuportUsLink>
                </SupportUsWrapper>
            </SupportUsContainer>
        </Container>
    )
}

export default PageNotFound