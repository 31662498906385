export const aboutObj = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Free of cost',
    headline: 'All the apps & sites are free to use.',
    description: 'Get access to our exclusive apps & sites without getting charged any fees.',
    buttonLabel: 'Explore',
    buttonRoute: 'products',
    imgStart: false,
    img: require('../../images/about.svg').default,
    //img: 'https://drive.google.com/uc?id=1jKC3UXbVlCEwSvrAeDvmBgBZh-RDHW4o',
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    link: ''
}

export const supportObj = {
    id: 'support',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Support Us',
    headline: 'Buy our developer a coffee.',
    description: 'You can support us by donating so that we can buy our developer a coffee, which will encourage developer to develop more.',
    buttonLabel: 'Buy me a coffee',
    buttonRoute: '',
    imgStart: false,
    img: require('../../images/support.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    link: 'https://www.buymeacoffee.com/dockdevelopers'
}

export const contactObj = {
    imgStart: false
}

export const subscribeObj = {
    imgStart: true
}

export const suggestionContractObj = {
    imgStart: true
}

// export const homeObjTwo = {
//     id: 'about',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Free of cost',
//     headline: 'All the services are free to use.',
//     description: 'Get access to our exclusive sites & apps without getting charged any fees.',
//     buttonLabel: 'Get Started',
//     imgStart: true,
//     img: require('../images/svg-1.svg').default,
//     alt: 'Piggy',
//     dark: false,
//     primary: false,
//     darkText: true
// }