import React, {useState} from 'react'
import Video from '../../videos/video-1.mp4';
import { HoreContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import { Button } from '../ButtonElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    };

    //const vid = "https://drive.google.com/file/d/1NS7p12VhXok9uyA9BZJeO0mKjGppEtG7/view";
    const vid = ('/video-1.mp4');

    return (
        <HoreContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={vid} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Elevate Your Digital Voyage</HeroH1>
                <HeroP>
                    Build sustainable products for better tomorrow
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="suggestion" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true"
                    smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                        Get a Quote {hover ?
                        <ArrowForward /> : 
                        <ArrowRight />}
                    </Button>  
                </HeroBtnWrapper>
            </HeroContent>
        </HoreContainer>
    )
}

export default HeroSection
