import React from 'react'
import { ProductContainer, ProductH1, ProductWrapper, ProductCard, ProductIcon, ProductH2, ProductP} from './UpcomingElements';
import Icon1 from '../../images/scanner-1.png';
import Icon2 from '../../images/decoder-1.png';
import Icon3 from '../../images/todo-2.png';

const UpcomingSection = () => {

    return (
        <ProductContainer id="upcoming">
            <ProductH1>Upcoming Products(in-house)</ProductH1>
            <ProductWrapper>
                <ProductCard>
                    <ProductIcon src={Icon1} />
                    <ProductH2>QR Code Reader</ProductH2>
                    <ProductP>DockDevelopers inhouse smooth and ultra fast QR Code Reader</ProductP>
                </ProductCard>
                <ProductCard>
                    <ProductIcon src={Icon2} />
                    <ProductH2>Url Decode & Encode</ProductH2>
                    <ProductP>Easy online tool to URL Encode and URL Decode</ProductP>
                </ProductCard>
                <ProductCard>
                    <ProductIcon src={Icon3} />
                    <ProductH2>Todo List</ProductH2>
                    <ProductP>Keep a track of your activities.</ProductP>
                </ProductCard>
            </ProductWrapper>
        </ProductContainer>
    )
}

export default UpcomingSection;
