import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import SubscribePage from './pages/subscribe';
import PasswordGeneratorPage from './pages/client-dock-password-generator';
import LoveFormPage from './pages/loveform';
import LoveDashboardPage from './pages/lovedashboard.js';
import PaymentPage from './pages/payment';
import PaymentSuccessPage from './pages/paymentsuccess';
import PaymentNotFoundPage from './pages/pagenotfound';
import RoutesConstant from './constants/routes';

function App() {
  // const handleClose = () => {
  //   window.location = '/';
  // }

  return (
    <Router>
      <Routes>
        <Route path={RoutesConstant.HOME} element={<Home />} />
        <Route path={RoutesConstant.SUBSCRIBE} element={<SubscribePage />} />
        <Route path="/pingenerator" element={<PasswordGeneratorPage />} />
        <Route path="/love/dashboard" element={<LoveDashboardPage />} />
        <Route path="/love/:id" element={<LoveFormPage />} />
        <Route path="/payments" element={<PaymentPage />} />
        <Route path="/paymentsuccess" element={<PaymentSuccessPage />} />
        {/* <Route path={RoutesConstant.LOGIN} element={<LoginPage open={true} handleClose={handleClose} />} />
        <Route path={RoutesConstant.DASHBOARD} element={<ClientDashboardPage />} /> */}
        <Route path='*' exact={true} element={<PaymentNotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
