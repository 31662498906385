import React from 'react';
import { Container, SubscribeWrapper, SubscribeRow, Column1, Column2, FormWrap, FormContent, Form, FormH1, FormP, FormLabel, FormInput, FormTextArea, FormButton, ImgWrap, Img, SupportUsContainer, SupportUsWrapper, SuportUsLink, SupportUsImg } from './PaymentSuccess';
import img from '../../images/paymentsuccess.svg';
import fire from '../utilities/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BMCIcon from '../../images/bmc-button.svg';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const PaymentSuccess = ({ imgStart }) => {

    const [paymentSuccess, setpaymentSuccess] = React.useState(false);
    const [pleaseWait, setPleaseWait] = React.useState(true);
    const [statusMessage, setStatusMessage] = React.useState('');
    var state = {
        customer: {
            name: 'Guest',
            rating: '',
            comment: '',
            date: new Date().toString()
        }
    }
    // const paymentId = cookies.get('dd_client_pm_id').then((res)=> {
    //     alert('cookie');
    // }).catch((err) => {
    //     alert('Error');
    // });
    const paymentId = cookies.get('dd_client_pm_id');
    setTimeout(() => {
        if (paymentId) {
            const paymentStatus = axios.post('https://us-central1-dockdevelopers-27abb.cloudfunctions.net/checkPaymentStatus', {
                "data": { paymentId: paymentId }
            });
            paymentStatus.then((result) => {
                if (result && result.data && result.data.result && result.data.result.paymentSuccessStatus) {
                    setPleaseWait(false);
                    setpaymentSuccess(true);
                    //setStatusMessage("We are processing your payment. We will send you a mail once we recieve confirmation from your bank. It usually takes 2-3 hours. Please visit our homepage to explore our in-house products.");
                }
            }).catch((error) => {
                setPleaseWait(false);
                setpaymentSuccess(true);
                //setStatusMessage("We are processing your payment. We will send you a mail once we recieve confirmation from your bank. It usually takes 2-3 hours. Meanwhile, you can visit our homepage to explore wide range of in-house products.");
                console.log(error);
            })
        }
        else {
            setPleaseWait(false);
            setStatusMessage("Sorry, you don't have the access to view this page. Redirecting to Homepage in 5 seconds.....");
            setTimeout(() => {
                window.location.href = '/';
            }, 5000);
        }
    }, 200);


    return (

        <Container id='clientpasswordgenerator'>
            <ToastContainer />
            <SubscribeWrapper>
                <SubscribeRow imgStart={imgStart}>
                    <Column1>
                        <ImgWrap>
                            <Img src={img} alt="subscribe" />
                        </ImgWrap>
                    </Column1>
                    <Column2>
                        {pleaseWait && <FormP>Please wait a moment</FormP>}
                        {!pleaseWait && statusMessage && <FormP>{statusMessage}</FormP>}
                        {paymentSuccess && <h3 className="couponDesc">Thanks, we have recieved your payment request.</h3>}<br />
                        {paymentSuccess && <FormP>We will send you confirmation mail once we get an update from your bank. It usually takes around 2-3 hours.</FormP>}
                        {paymentSuccess && <FormP>Meanwhile, you can explore our wide range of products and services by visiting our <a className="primaryText" href="/">homepage</a>.</FormP>}
                        {/* {pleaseWait} && {!paymentSuccessfull} && <FormH1>Something went wrong...</FormH1> */}
                        {/* <FormWrap>
                            <FormContent>
                                <Form onSubmit={handlePayment} id="client-form">
                                    <FormH1>Hello, {name}</FormH1>
                                    <FormP>Thanks for giving us an opportunity to serve you. We hope, you are satisfied with our work.<br /> Please rate us and add your comments or suggestions to help us improve.</FormP>
                                    <FormLabel htmlFor='rating'>Rate Us</FormLabel>
                                    <div className="rating-container">
                                        <a id="ratingOne" onClick={handleOneRatingChanged.bind()}>1</a>
                                        <a id="ratingTwo" onClick={handleTwoRatingChanged.bind()}>2</a>
                                        <a id="ratingThree" onClick={handleThreeRatingChanged.bind()}>3</a>
                                        <a id="ratingFour" onClick={handleFourRatingChanged.bind()}>4</a>
                                        <a id="ratingFive" onClick={handleFiveRatingChanged.bind()}>5</a>
                                    </div>
                                    <FormLabel htmlFor='comment'>Comment</FormLabel>
                                    <textarea className="client-comment" name="comment" type='text' rows="5" onChange={handleCommentChanged.bind()} placeholder="Type your comment"></textarea>
                                    <FormButton type='submit'>Make Payment</FormButton>
                                    <FormP>Contact us if you need any help, we will be more than happy to help you out in any possible way.</FormP>
                                </Form>
                            </FormContent>
                        </FormWrap> */}
                    </Column2>
                </SubscribeRow>
            </SubscribeWrapper>

            <SupportUsContainer>
                <SupportUsWrapper>
                    <SuportUsLink href="https://www.buymeacoffee.com/dockdevelopers" target="_blank"><SupportUsImg src={BMCIcon} /></SuportUsLink>
                </SupportUsWrapper>
            </SupportUsContainer>
        </Container>
    )
}

export default PaymentSuccess