import React, { useState, Fragment } from 'react'
import LoveNavbar from '../components/LoveNavbar'
import LoginForm from '../components/LoginForm';
import LoveDashboard from '../components/LoveDashboard';
import { ToastContainer } from 'react-toastify';
import Footer from '../components/Footer';

const LoveDashboardPage = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [id, setId] = useState('');
    const [data, setDate] = useState();

    const onLogin = (id, data) => {
        setId(id);
        setDate(data);
        setLoggedIn(true);
    }

    return (
        <Fragment>
            <ToastContainer />
            {/* <LittleSidebar isOpen={isOpen} toggle={toggle} /> */}
            {
                    <>
                        <LoveNavbar title='DockDevelopers' />
                        { !loggedIn ? (<LoginForm onLogin={onLogin} />) : (<LoveDashboard id={id} data={data} />) }
                    </>
            }
            <Footer />
        </Fragment>
    )
}

export default LoveDashboardPage;
